import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, useColorModeValue } from "design-system";

import Dock from "~/components/Dock";
import { MobileNavigationBar } from "~/components/MobileNavigationBar";

export const DefaultLayoutDock = (props) => (
  <Box gridArea="dock" d={{ base: "none", md: "initial" }} {...props} />
);

export const DefaultLayoutHeader = (props) => {
  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.700", "gray.200");

  return (
    <Box
      gridArea="header"
      borderBottomWidth={1}
      bg={bg}
      color={color}
      {...props}
    />
  );
};

export const DefaultLayoutContent = (props) => {
  const bg = useColorModeValue("gray.50", "gray.700");
  const color = useColorModeValue("gray.700", "gray.200");

  return (
    <Box gridArea="content" overflowY="auto" bg={bg} color={color} {...props} />
  );
};

export const DefaultLayoutFooter = (props) => (
  <Box gridArea="footer" d={{ base: "initial", md: "none" }} {...props} />
);

export function DefaultLayout({ children }) {
  return (
    <Grid
      w="100%"
      h="100%"
      overflow="hidden"
      templateAreas={{
        base: "'header' 'content' 'footer'",
        md: "'dock header' 'dock content'",
      }}
      templateColumns={{
        base: "1fr",
        md: "fit-content(0) 1fr",
      }}
      templateRows={{
        base: "fit-content(0) 1fr fit-content(0)",
        md: "fit-content(0) 1fr",
      }}
    >
      <DefaultLayoutContent>{children}</DefaultLayoutContent>
      <DefaultLayoutDock>
        <Dock />
      </DefaultLayoutDock>
      <DefaultLayoutFooter>
        <MobileNavigationBar />
      </DefaultLayoutFooter>
    </Grid>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
};
