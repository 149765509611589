import React from "react";
import { Box, Stack, Heading, Text, Divider } from "design-system";
import PropTypes from "prop-types";

import { DefaultLayout } from "./DefaultLayout";

export function BasicLayout({ children, meta }) {
  return (
    <DefaultLayout>
      <Stack spacing={16} maxWidth="4xl" mx="auto" px={4} my={16}>
        <Stack as="header" spacing={2}>
          <Heading as="h1" size="lg">
            {meta?.title}
          </Heading>
          <Text>{meta?.description}</Text>
        </Stack>
        <Divider />
        <Box>{children}</Box>
      </Stack>
    </DefaultLayout>
  );
}

BasicLayout.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};
