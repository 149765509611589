import React from "react";
import { Text, VStack, Stack, Flex, Box, Button, Tag } from "design-system";
import Link from "next/link";
import PropTypes from "prop-types";

import { PAGE_TYPES } from "~/constants/page-types";
import { BasicLayout } from "~/layouts/BasicLayout";

function ExhibitCard({ href, title, author, image }) {
  return (
    <Link passHref href={href}>
      <Flex
        as="a"
        justify="center"
        align="flex-end"
        bg="gray.200"
        bgPosition="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        boxShadow="md"
        borderRadius="md"
        w="full"
        h={64}
        p={8}
        color="white"
        position="relative"
        overflow="hidden"
        zIndex={0}
      >
        <Box
          as="img"
          src={image}
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          objectFit="cover"
          zIndex={0}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bgGradient="linear(to-b, transparent, blackAlpha.800)"
          zIndex={1}
        />
        <VStack spacing={0} zIndex={2} textAlign="center">
          <Text
            as="h3"
            fontSize="lg"
            letterSpacing="tight"
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Text fontSize="sm">{author}</Text>
        </VStack>
      </Flex>
    </Link>
  );
}

ExhibitCard.propTypes = {
  href: PropTypes.string.isRequred,
  title: PropTypes.string.isRequred,
  author: PropTypes.string.isRequred,
  image: PropTypes.string.isRequred,
};

export default function ExplorePage() {
  return (
    <>
      <Stack as="section" align="stretch" spacing={4}>
        <ExhibitCard
          title="Museum of American War Letters"
          author="Andrew Carroll"
          // href="/exhibit/d27640e2-f8bf-4ea7-bcca-37aed9dd61bc/story/8da5559d-1008-484a-ab3e-5862b2439885"
          href="https://world.treasured.ca/player/MuseumOfAmericanWarLetters"
          image="/static/images/explore/war-letters.jpeg"
        />
        <ExhibitCard
          title="Portraits of the Land"
          author="King Heritage and Cultural Centre"
          href="/exhibit/990c3f4d-ddab-45b0-94f5-9baa47ceb3db/collection/43eef2c3-f940-4d8e-be87-4d2400296daa"
          image="/static/images/explore/portraits-of-the-land.jpeg"
        />
        <ExhibitCard
          title="Stories of King"
          author="King Heritage and Cultural Centre"
          href="/exhibit/4ad73051-e8df-4f2b-af35-444aad1961d0/collection/cef3c95f-bd75-405b-b838-0cfd274fb54e"
          image="/static/images/explore/stories-of-king.jpg"
        />
        <ExhibitCard
          title="Tools of the Trade"
          author="Fanshawe Pioneer Village"
          href="/exhibit/765379eb-46dc-4dd6-9b37-5008a82a7120/collection/1dc15750-880c-41f6-8ac3-f225408ae68c"
          image="/static/images/explore/tools-of-the-trade.jpeg"
        />
        <ExhibitCard
          title="Welcome to the Museum on the Boyne"
          author="The Museum on the Boyne"
          href="/exhibit/0218900f-4e27-4c4e-b0bb-8cd1637e61aa/collection/9853ecfe-9912-4527-9771-a1703f508e07"
          image="/static/images/explore/museum-on-the-boyne.jpeg"
        />

        <Box
          bg="white"
          bgImage={{
            base: undefined,
            lg: "url(/static/images/explore/more.png)",
          }}
          bgPos="right center"
          bgSize="cover"
          bgRepeat="no-repeat"
          borderWidth={1}
          p={{ base: 8, md: 12 }}
          borderRadius="md"
        >
          <Stack
            w={{ base: undefined, lg: "60%" }}
            align="flex-start"
            spacing={6}
          >
            <Box>
              <Tag
                colorScheme="green"
                size="sm"
                textTransform="uppercase"
                fontWeight="semibold"
                letterSpacing="tight"
              >
                Curators Program
              </Tag>
              <Text
                as="h2"
                fontSize="3xl"
                fontWeight="bold"
                letterSpacing="tighter"
              >
                Got an idea for an exhibit?
              </Text>
              <Text color="gray.500">
                We&apos;re currently looking for new partners for our{" "}
                <strong>Curators Program</strong>. Book a time with us to find
                out how it works.
              </Text>
            </Box>
            <Button
              as="a"
              href="https://treasured.ca/demo.html"
              colorScheme="brand"
            >
              Book a Demo
            </Button>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

ExplorePage.layoutProps = {
  meta: {
    title: "Explore",
    description: "Discover stories from local museums",
  },
  Layout: BasicLayout,
};

ExplorePage.pageType = PAGE_TYPES.PUBLIC;
